import React from "react"
import { graphql } from "gatsby"

import Content from "../components/content"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "./about.module.scss"


export default function About({ data, location }) {
    const page = data.wagtailbase.page
    return (
        <Layout location={location}>
            <SEO
              title={page.title}
              url={location.href}
            />
            <article className={styles.articleContainer}>
                <header className={styles.articleHeader}>
                    <h1 className={styles.articleTitle}>{page.title}</h1>
                </header>
                    <Content content={page.content} />
            </article>
        </Layout>
    )
}

export const query = graphql`
    query {
        wagtailbase {
            page(slug: "about") {
              ... on WagtailBase_AboutPage {
                title
                content {
                  id
                  rawValue
                  blockType
                  ...on WagtailBase_RichTextBlock {
                    value
                  }
                  ...on WagtailBase_StructBlock {
                    blocks {
                      field
                      rawValue
                    }
                  }
                }
              }
            }
        }
    }
`
